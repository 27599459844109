

































































import { Component, Vue, Watch } from "vue-property-decorator";
import dashboardModule from "@/store/modules/dashboard-module";
import agrupacion_importacionesDtoModule from "@/store/modules/agrupacion_importacionesDto-module";
import ayuntamientoModule from "@/store/modules/ayuntamiento-module";
@Component({
  components: {
    GaugesChart: () => import("@/components/Charts/GaugesChart.vue"),
    GaugesChartNormal: () =>
      import("@/components/Charts/GaugesChartNormal.vue"),
    DropDownMultipleSelectionList: () =>
      import("@/components/DropsDown/DropDownMultipleSelectionList.vue"),
    GaugesChartComparation: () =>
      import("@/components/Charts/GaugesChartAyuntamiento.vue"),
    GaugesChartNormalComparation: () =>
      import("@/components/Charts/GaugesChartNormalAyuntamiento.vue"),
  },
})
export default class DashboardAdmin extends Vue {
  public ids_filtrados: number[] = [];
  public ids_filtrados_ayuntamientos: number[] = [];
  public habilitar_filtros: boolean = false;

  public async created() {
    await agrupacion_importacionesDtoModule.getagrupacion_importacionesDtos();
    this.ids_filtrados = this.agrupaciones.map((x) => x.id);
    await ayuntamientoModule.getayuntamientos();
    this.ids_filtrados_ayuntamientos = this.ayuntamientos.map((x) => x.id);

    dashboardModule.getgaugesdashboards();
    dashboardModule.getgauge_general();
    this.habilitar_filtros = true;
  }

  public get dashboards() {
    if (this.seleccion_todos) {
      return dashboardModule.gauges_dashboard;
    } else {
      return dashboardModule.gauges_dashboard_ayuntamientos;
    }
  }
  public get dashboardGeneral() {
    if (this.seleccion_todos) {
      return dashboardModule.gauge_general;
    } else {
      return dashboardModule.gauge_general_ayuntamiento;
    }
  }
  public get agrupaciones() {
    return agrupacion_importacionesDtoModule.agrupacion_importacionesDtos;
  }
  public get ayuntamientos() {
    return ayuntamientoModule.ayuntamientos;
  }
  public get seleccion_todos() {
    return (
      this.ids_filtrados_ayuntamientos.length === this.ayuntamientos.length
    );
  }
  @Watch("ids_filtrados")
  public ChangeDataAgrupacion() {
    this.ChangeData();
  }
  @Watch("ids_filtrados_ayuntamientos")
  public ChangeDataAyuntamiento() {
    this.ChangeData();
  }
  public ChangeData() {
    if (!this.habilitar_filtros) {
      return;
    }
    if (this.seleccion_todos) {
      dashboardModule.getgaugesdashboards_filtado({
        agrupaciones: this.ids_filtrados,
        ayuntamientos: this.ids_filtrados_ayuntamientos,
      });
      dashboardModule.getgauge_general_filtado({
        agrupaciones: this.ids_filtrados,
        ayuntamientos: this.ids_filtrados_ayuntamientos,
      });
    } else {
      dashboardModule.getgaugesdashboards_ayuntamiento({
        agrupaciones: this.ids_filtrados,
        ayuntamientos: this.ids_filtrados_ayuntamientos,
      });

      dashboardModule.getgauge_general_ayuntamiento({
        agrupaciones: this.ids_filtrados,
        ayuntamientos: this.ids_filtrados_ayuntamientos,
      });
    }
  }
}
